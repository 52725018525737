
<template>
  <div class="bg">
    <div class="wrapper">
      <div class="activity">在线考试</div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const fileList = [
      {
        uid: '-1',
        name: 'ice.png',
        url: '//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/3ee5f13fb09879ecb5185e440cef6eb9.png~tplv-uwbnlip3yd-webp.webp'
      },
      {
        status: 'error',
        uid: '-2',
        percent: 0,
        response: '上传错误提示',
        name: 'cat.png',
        url: '//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/e278888093bef8910e829486fb45dd69.png~tplv-uwbnlip3yd-webp.webp'
      },
      {
        uid: '-3',
        name: 'light.png',
        url: '//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/a8c8cdb109cb051163646151a4a5083b.png~tplv-uwbnlip3yd-webp.webp'
      }
    ]
    const customRequest = (file) => {
      console.log(file, fileList)
    }
    return {
      fileList,
      customRequest
    }
  }
}
</script>

<style lang="less" scoped>
.bg {
  background: #F7F6FA;
  padding: 20px 0;
  .activity {
    background: #fff;
    padding: 20px;
  }
}

</style>
